<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['refunddele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li v-if="$store.state.villageId==0">
					<el-select clearable filterable v-model="form.village_id" placeholder="请选择村">
						<el-option v-for="item in cunlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.name" placeholder="请输入名称">
					</el-input>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.order_sn" placeholder="请输入订单号">
					</el-input>
				</li>
				<li>
					<el-select clearable v-model="form.refund_tab" placeholder="请选择状态">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="申请中" :value="1"></el-option>
						<el-option label="退款失败" :value="2"></el-option>
						<el-option label="已退款" :value="3"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="name" label="村">
					<template #default="scope">
						<span>{{scope.row.shop?scope.row.shop.name:''}}</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="姓名" />
				<el-table-column property="pay_price" label="订单金额" />
				<el-table-column property="order_sn" label="订单编号" />
				<el-table-column property="product_num" label="商品数量" />
				<el-table-column property="name" label="是否支付">
					<template #default="scope">
						<span>{{scope.row.pay_status==1?'是':'否'}}</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="是否发货">
					<template #default="scope">
						<span>{{scope.row.is_delivery==1?'是':'否'}}</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="是否收货"><template #default="scope">
						<span>{{scope.row.is_received==1?'是':'否'}}</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="是否退货">
					<template #default="scope">
						<span>{{scope.row.is_return_goods==1?'是':'否'}}</span>
					</template>
				</el-table-column>
				<el-table-column property="refuse_reason" label="拒绝原因">
					<template #default="scope">
						<span>{{scope.row.orderRefund.refuse_reason}}</span>
					</template>
				</el-table-column>
				<el-table-column property="status_text" label="状态" />
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="300" v-if="$store.state.villageId>0">
					<template #default="scope">
						<el-button v-permission="['refundsee']" @click="detail(scope.row.order_sn)"
							size="mini">查看详情</el-button>
						<el-button v-permission="['orderlogistics']"
							@click="logistics(scope.row.order_sn,scope.row.delivery_name,scope.row.delivery_id)"
							size="mini">查看物流</el-button>

						<el-button v-permission="['refundedits']" @click="edits(scope.row.order_sn)"
							size="mini">编辑</el-button>
						<el-button v-if="scope.row.button.refund_approved_button" v-permission="['refundpass']"
							size="mini" @click="approved(scope.row.order_sn)">同意</el-button>
						<el-button v-if="scope.row.button.refund_rejet_button" v-permission="['refundrefuse']"
							@click="refuse(scope.row.order_sn)" size="mini">拒绝</el-button>
						<el-button v-permission="['refunddele']" size="mini"
							@click="dele(scope.row.order_sn)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 详情 -->
		<detail ref="detail"></detail>
		<!-- 编辑 -->
		<edits ref="edits" @SubmitForm="getList"></edits>
		<!-- 拒绝 -->
		<refuse ref="refuse" @SubmitForm="getList"></refuse>
		<!-- 物流 -->
		<logistics ref="logistics"></logistics>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/order";
	import detail from '../components/details.vue'
	import edits from '../components/edits.vue'
	import refuse from './components/refuse.vue'
	import logistics from '../components/logistics.vue'
	export default {
		components: {
			detail,
			edits,
			refuse,
			logistics
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					type: 2,
					page: 1,
					limit: 15,
					refund_tab: 0,
					order_sn: '',
					name: '',
					village_id:''
				},
				tableData: [],
				idlist: [],
				cunlist: []
			}
		},
		created() {
			if (this.$route.query.state) {
				this.form.refund_tab = Number(this.$route.query.state)
				this.getList()
			}
			this.getList()
			this.getcun()
		},
		methods: {
			// 获取列表
			getcun() {
				this.$http.post(api.cunlist, {
						page: 1,
						limit: 999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.cunlist = res.data.data
						}
					})
			},
			// 查看物流
			logistics(order_sn, name, code) {
				this.$refs.logistics.show(order_sn, name, code)
			},
			// 同意
			approved(order_sn) {
				ElMessageBox.confirm(
						'是否确定同意',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.approved, {
								order_sn: order_sn,
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '同意成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
			},
			// 拒绝
			refuse(order_sn) {
				this.$refs.refuse.show(order_sn)
			},
			// 编辑
			edits(order_sn) {
				this.$refs.edits.show(order_sn)
			},
			// 查看详情
			detail(order_sn) {
				this.$refs.detail.show(order_sn, 1)
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.type = 2
				this.form.refund_tab = 0
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.order_sn)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									order_sn: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(order_sn) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								order_sn: order_sn
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>