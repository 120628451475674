<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			title="拒绝原因">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="原因">
						<span class="xx">*</span>
						<el-input type="textarea" clearable v-model="ruleForm.refuse_reason" placeholder="请输入拒绝原因">
						</el-input>
					</el-form-item>

				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/order";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					order_sn: '',
					refuse_reason: "",
				}

			}
		},
		methods: {
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.refuse_reason === '') {
					ElMessage({
						message: '请输入拒绝原因',
						type: 'warning',
					})
				} else {
					this.$http.post(api.reject, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '拒绝成功',
								})
								this.$emit('SubmitForm')
								this.outerVisible = false
							}
						})
				}
			},

			// 显示隐藏
			show(order_sn) {
				this.ruleForm.order_sn = order_sn
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>
